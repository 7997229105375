.fc-content {
	.fc-event-skin {
		border: 0 !important;
		background-color: #6884A7 !important;
	    border-color: transparent !important;
	    color: #FFF !important;
	}

	.fc-event {
		border: 1px solid #2A3542 !important;
	}

	.fc-event-vert .fc-event-bg {
	    background-color: #6884A7 !important;
	}


}

.fc-button-next, .fc-button-prev {
	font-size: 20px;

	.fc-button-content {
	    height: auto;
    	line-height: 25px;
    	padding: 0 11px;
	}
}

.help-block {
	color: #2A3542;
	font-weight: 400;
}

.toolbar {
	margin-bottom: 5px;
}

.modal-message {
	display: none;
	font-size: 14px;
	margin-top: 10px;
	font-weight: 300;
}

#sidebar {
    z-index: 11;
}

.site-footer {
	z-index: 10;
}

.form-control-static {
    padding-top: 8px;
    font-size: 14px;
}

.form-control-feedback {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    display: block;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    pointer-events: none;
}

.encabezados {
	background-color: #529F71;
	border-radius: 5px;
	padding: 10px 5px;
    margin-bottom: 10px;

	& > div {
		text-align: center;
		font-size: 15px;
		font-weight: 400;
		color: #FFF;
	}
}

.login-body {
	background: url(../images/login-bg.jpg) #000 no-repeat center center fixed;
	background-size: cover;
}

.login-wrapper {
	display: table;
	width: 100%;
	height: 100vh;

	.login-container {
		display: table-cell;
		vertical-align: middle;
		top: -100px;
		position: relative;

		.login-logo {
			text-align: center;
			margin-top: 40px;
		}

		.form-signin {
		    margin: 20px auto 0;
		}
	}
}

#regalo {

	background-color: #FFF;

	.brand {
		
		padding: 15px;

		.brand__logo {
			padding-top: 20px;
		}

		.brand__followtext {
			font-size: 15px;
			font-weight: 400;
		}

		.brand__telephone {
			font-size: 18px;
			font-weight: 700;
			color: #016FD1;
		}

		.brand__social {

			ul.social-networks {
				list-style-type: none;
				margin: 0;
				
				li {
					
					display: inline-block;

					a {
						padding: 6px;
						margin-right: 0px;
						background-color: #4CBF9F;
						color: #FFF;
						height: 35px;
						width: 35px;
						display: inline-block;
						text-align: center;
						font-size: 17px;

					    &:hover {
					    	background-color: darken(#4CBF9F, 10%);
					    }
					}
				}
			}
		}
	}

	
}
